//End Use Readings
//Site Slicer - vwSite - SiteName
//Date Slicer - vwReportingDateTime - Date
//UOM Slicer - UnitOfMeasureSelection - PowerBiFriendlyName
//End Use Slicer - vwEndUseNames - EndUseName

import cons from '../constants';
//import * as powerbi from "powerbi-client";

const setSlicers = async (reportParameters, visuals, constrainDays = false) => {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setDateSlicerVisual(reportParameters, visuals, constrainDays);
  await setEndUseSlicerVisual(reportParameters, visuals);
};

const setSiteSlicerVisual = async (reportParameters, visuals) => {
  const siteSlicerFilter = {
    $schema: cons.schemaUrlAdv,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };
  const siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
    console.log('Site slicer was set.');
  }
};

const setDateSlicerVisual = async (
  reportParameters,
  visuals,
  constrainDays = false
) => {
  const endDate = new Date(reportParameters.EndDate);
  endDate.setDate(endDate.getDate() - 2);

  const dateSlicerFilter = {
    $schema: cons.schemaUrlAdv,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: constrainDays
          ? endDate.toISOString()
          : reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  const dateSlicerVisual = visuals.filter((visual) => {
    return visual.type === 'slicer' && visual.title === 'Date Slicer';
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
    console.log('Date slicer was set.');
  }
};
const setEndUseSlicerVisual = async (reportParameters, visuals) => {
  console.log('Setting End Use slicer.', reportParameters);
  const endUseSlicerFilter = {
    $schema: cons.schemaUrlTopN,
    target: {
      table: 'vwEndUseNames',
      column: 'EndUseName',
    },
    operator: 'Top',
    itemCount: 1,
    orderBy: {
      table: 'vwEndUseNames',
      measure: 'EndUseName',
    },
    filterType: 5,
  };

  let endUseSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'End Use Slicer';
  })[0];

  if (endUseSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await endUseSlicerVisual.setSlicerState({
      filters: [endUseSlicerFilter],
    });
    console.log('End Use slicer was set.');
  }
};

export default {
  setSlicers,
};
