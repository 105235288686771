<template>
  <div>
    <v-app-bar fixed app height="88" color="white">
      <v-toolbar-title class="headline text-uppercase">
        <v-img
          class="mt-2"
          :class="{ 'ml-n10': smallBreakpoint }"
          src="./images/E360-Blue-with-Logo.svg"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <MessageCenterUserButton
        @openMessagesCenter="dialog = true"
        v-if="!showWarningBanner && !isAllowAnonymous"
      />
      <!-- <BreadCrumbs /> -->
      <WarningBanner v-if="showWarningBanner && !isAllowAnonymous" />
    </v-app-bar>
    <MessageCenterDialog :dialog="dialog" @close="dialog = false" />
  </div>
</template>
<script>
// const BreadCrumbs = () => import('@/Core.UI.Domain/Components/BreadCrumbs.vue')
import WarningBanner from '@/Core.UI.Domain/Components/WarningBanner.vue';

import MessageCenterDialog from '@/Core.Service.Domain/MessageCenter/Users/Components/MessageCenterDialog.vue';

import MessageCenterUserButton from '@/Core.Service.Domain/MessageCenter/Users/Components/MessageCenterUserButton.vue';

import { DateTime } from 'luxon';

export default {
  name: 'AppBar',

  components: {
    // BreadCrumbs,
    WarningBanner,
    MessageCenterDialog,
    MessageCenterUserButton,
  },

  computed: {
    isAllowAnonymous() {
      return this.$route.meta.allowAnonymous;
    },
  },

  created() {
    this.hideBannerAtPSTTime();
  },

  data: () => ({
    showWarningBanner: false,
    dialog: false,
    targetDate: '2023-12-29',
    hour: 20,
    minute: 0,
  }),

  methods: {
    hideBannerAtPSTTime() {
      const currentDate = DateTime.now().setZone('America/Los_Angeles');

      if (currentDate.toFormat('yyyy-MM-dd') !== this.targetDate) {
        return;
      }

      const checkTimeInterval = 30 * 1000;

      const checkTime = () => {
        const nowPST = DateTime.now().setZone('America/Los_Angeles');
        const targetTime = nowPST.set({
          hour: this.hour,
          minute: this.minute,
          second: 0,
          millisecond: 0,
        });

        if (nowPST < targetTime) {
          this.showWarningBanner = true;
        } else {
          this.showWarningBanner = false;
        }

        setTimeout(checkTime, checkTimeInterval);
      };

      checkTime();
    },
  },
};
</script>
